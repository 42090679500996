import React from "react"
import Blogs from "../../core/BlogCard"
// import { chunk } from "./../../../../src/util/helper"

export const BlogCardBlock = ({ BlogCard, K }) => {
  const { blogs } = BlogCard
  return (
    <div className="py-6 bg-transperent">
      <div className="relative max-w-7xl mx-auto lg:px-0 md:px-0 px-5" data-aos="fade-up">
        <div
          className="mt-5 mb-12 grid gap-10 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none"
        >
          {blogs?.map(f => (
            <Blogs {...f} key={Math.random()} />
          ))}
        </div>
        {!K ? (
          <div className={`mt-16 flex justify-center `}>
            <div className={`inline-flex justify-center lg:justify-start `}>
              <a
                href="/blog/"
                className="bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded"
              >
                See more
              </a>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

export default BlogCardBlock
