import React from "react"
import P from "./P"

const BlogCard = ({
  title,
  uri,
  featuredImage,
  excerpt,
  categories,
  author,
  date,
}) => (
  <div key={Math.random()} className="flex flex-col overflow-hidden">
    <a href={`/blog` + uri}>
      <div className="flex justify-center flex-col overflow-hidden relative">
        {/* <div className="flex-shrink-0 img-fade common-index"> */}
        <div className="flex-shrink-0  imgBlog common-index">
          <div>
            <img
              // className="h-auto w-full object-cover opacity-50"
              className="h-auto w-full object-cover"
              src={featuredImage?.node?.mediaItemUrl}
              width="1024"
              height="576"
              alt="Blog card"
              title="Blog card"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </a>
    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
      <div className="flex-1">
        <p className="text-sm leading-5 font-medium text-blue-500">
          <a
            href={`/blog` + categories.nodes[0].link}
            className="hover:underline"
          >
            {categories.nodes[0].name}
          </a>
        </p>

        <a href={`/blog` + uri}>
          <div className="block">
            <P
              noPad
              className="mt-2  leading-7 font-semibold text-shark-500"
              title={title}
            />
            <div
              className="mt-3 leading-6 text-shark-400 text-lg font-sans max-w-5xl"
              dangerouslySetInnerHTML={{ __html: excerpt }}
            />
          </div>
        </a>
      </div>
      <a href={`/blog` + author.node.uri}>
        <div className="mt-6 flex items-center">
          <div className="flex-shrink-0">
            <img
              className="h-10 w-10 rounded-full"
              src={author.node.avatar.url}
              alt="Avtar"
              title="Avtar"
              loading="lazy"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm leading-5 font-medium text-gray-900">
              {author.node.name}
            </p>
            <div className="flex text-sm leading-5 text-gray-700">
              <time dateTime="2020-03-16">{date}</time>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
)

export default BlogCard
